.homepage__container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: url('../../assets/images/homepage-hero.jpg');
  background-size: cover;
}

.homepage__hero-grid {
  background-color: rgba(0 ,0 , 0, 0.35);
  height: 100vh;
  width: 100vw;
}

.homepage__hero-heading {
  color: white;
  font-size: 4em;
  text-align: center;
  text-shadow: 2px 2px 15px #000000;
}

.homepage__hero-subheading {
  color: white;
  font-size: 2em;
  text-align: center;
  width: 50%;
}

.homepage__hero-cta {
  padding-top: 24px;
}

.homepage__hero-cta-button {
  margin: 6px!important;
}

.homepage__hero-cta-button--secondary {
  @extend .homepage__hero-cta-button;
  color: white!important;
  border-color: white!important;
}

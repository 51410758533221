@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
  background-color: $background;
  font-family: 'Open Sans', sans-serif;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.dashboard__container {
  padding: 24px;
}

.dashboard__overview-card {
  text-align: center;
  padding: 12px;
}

.dashboard__overview-card-icon > .MuiSvgIcon-root {
  font-size: 40px!important;
}

.dashboard__overview-card-title {
  font-weight: 200;
  font-size: 12px;
}

.dashboard__overview-card-value {
  font-weight: 400;
  font-size: 24px;
}

.dashboard__accounts-table {
  background-color: white!important;
}

.dashboard__payment-heading {
  font-weight: 400;
  font-size: 24px;
  width: fit-content;
  padding-bottom: 12px;
  border-bottom: 1px solid black;
}

.dashboard__payment-total {
  margin: 12px 0;
}

.dashboard__payment-qr-placeholder {
  min-height: 200px;
  border: 1px dashed black;
  width: 100%;
}

.register__container {
  padding: 24px;
}

.register__heading-wrapper {
  text-align: center;
  padding: 12px;
}

.register__credential-box-wrapper {
  padding: 12px 24px;
}
